let next = 1000
let untitledCount = 1

function getNext(): number {
  return next++
}

export function newId(prefix: string): string {
  return `${prefix}_${getNext()}`
}

export function newName(): string {
  return `Untitled ${untitledCount++}`
}
