import React, { useState } from 'react'
import { ChoppingBoard } from './ChoppingBoard'
import { PostChop } from './PostChop'
import { Block } from '../model/types'
import pretty from 'pretty'
import { newId, newName } from '../util/igden'

enum ChopStep {
  FindChopPoints = 1,
  InspectBlocks,
}

interface Props {
  chopped?: (blocks: Block[], themeDml: string) => void
}

export function ChopWizard(props: Props) {
  const [step, setStep] = useState(ChopStep.FindChopPoints)
  const [blocks, setBlocks] = useState<Block[]>([])

  function explode(parts: Document[], themeDml: string) {
    const newBlocks = parts.map(docToBlock)
    if (props.chopped) {
      props.chopped(newBlocks, themeDml)
    }
    setBlocks(newBlocks)
    setStep(ChopStep.InspectBlocks)
  }

  if (step === ChopStep.FindChopPoints) {
    return <ChoppingBoard explode={explode} />
  } else if (step === ChopStep.InspectBlocks) {
    return <PostChop blocks={blocks} />
  }

  throw new Error('unknown step in chop wizard')
}

function docToBlock(doc: Document): Block {
  const innerHtml = pretty(doc.body ? doc.body.innerHTML : '')
  return {
    id: newId('blk'),
    name: newName(),
    dml: `<dys-block>\n  <dys-html>\n    ${innerHtml}\n  </dys-html>\n</dys-block>`,
  }
}
