import React, { useState, useEffect } from 'react'
import { Block } from '../model/types'
import styled from 'styled-components'
import { Window } from './Window'
import { Editor } from './Editor'

const BlocksWrapper = styled.div``

const SideBySide = styled.div`
  display: grid;
  grid-template-columns: 700px 700px;
`

interface Props {
  blocks: Block[]
}

export function PostChop(props: Props) {
  return (
    <BlocksWrapper>
      {props.blocks.map((b, i) => (
        <Window key={i}>
          <SideBySide>
            <Editor
              language="html"
              value={b.dml}
              width="100%"
              height="250px"
              onChange={() => {}}
            />
            <Preview dml={b.dml} />
          </SideBySide>
        </Window>
      ))}
    </BlocksWrapper>
  )
}

const PreviewFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  border-left: 1px solid black;
`

interface PreviewProps {
  dml: string
}

function Preview(props: PreviewProps) {
  const [html, setHtml] = useState('')
  useEffect(() => {
    setHtml(`<html><body style="margin: 0;">${props.dml}</body></html>`)
  }, [props.dml])
  return <PreviewFrame srcDoc={html} />
}
