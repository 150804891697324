import React from 'react'
import ReactDOM from 'react-dom'
import './main/index.css'
import App from './components/App'
import { loadWasm } from './util/dml'

// Start loading the wasm lib right away
loadWasm()

ReactDOM.render(<App />, document.getElementById('root'))
