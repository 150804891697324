import { redAlert } from './errors'
import './wasm_exec'

export async function loadWasm() {
  try {
    const Go = (global as any).Go
    const go = new Go()
    const headers = new Headers()

    const wasmFile = await fetch('dml.wasm', { method: 'GET', headers })
    const wasmBytes = await wasmFile.arrayBuffer()
    const wasm = await WebAssembly.instantiate(wasmBytes, go.importObject)
    await go.run(wasm.instance)
  } catch (ex) {
    redAlert('Failed to load wasm :(')
    console.error(ex)
    throw ex
  }
}

export function hasLoaded(): boolean {
  return !!(window as any).dml
}

export function render(blockDml: string, themeDml: string): string | null {
  if (hasLoaded()) {
    const renderResult = (window as any).dml.render(blockDml, themeDml)
    if (renderResult) {
      return renderResult.html as string
    }
    console.error(renderResult)
  }
  return null
}
