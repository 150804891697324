interface Theme {
  backgroundUrl: string | undefined
  backgroundColor: string | undefined
}

export function excise(doc: Document | null): string {
  if (!doc) {
    return ''
  }
  const theme = {
    backgroundUrl: getBackgroundUrl(doc),
    backgroundColor: getBackgroundColor(doc),
  }
  return themeToString(theme)
}

function getBackgroundUrl(doc: Document): string | undefined {
  const styles = getInlineStyle(doc)
  const bgImg = styles['background-image']
  if (!bgImg) {
    return
  }
  return urlFromBackgroundImageCssAttr(bgImg)
}

function getBackgroundColor(doc: Document): string | undefined {
  const styles = getInlineStyle(doc)
  return styles['background-color'] || '#fff'
}

function themeToString(theme: Theme): string {
  return `<dys-attributes>\n${getThemeBodyString(theme)}</dys-attributes>`
}

function getThemeBodyString(theme: Theme): string {
  const attrs = [] as string[]
  if (theme.backgroundColor) {
    attrs.push(`background-color="${theme.backgroundColor}"`)
  }
  if (theme.backgroundUrl) {
    attrs.push(`background-url="${theme.backgroundUrl}"`)
  }

  if (!attrs.length) {
    return ''
  }

  return `  <dys-body ${attrs.join(' ')} />\n`
}

type Styles = { [key: string]: string }

function getInlineStyle(doc: Document): Styles {
  const bodyStyle = doc.body.getAttribute('style')
  if (!bodyStyle) {
    return {}
  }

  return bodyStyle
    .split(';')
    .map(p => p.trim().split(':'))
    .reduce(
      (l: Styles, r: string[]): Styles => {
        l[r[0].trim()] = r
          .splice(1)
          .join(':')
          .trim()
        return l
      },
      {} as Styles
    )
}

function urlFromBackgroundImageCssAttr(attrVal: string): string | undefined {
  const match = attrVal.match(/url\((.*)\)/)
  if (!match) {
    return
  }
  return match[1]
}
