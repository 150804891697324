import React, { useEffect } from 'react'
import logo from '../main/logo.svg'

export function Loading() {
  return (
    <div>
      <h1>WAIT FOR WASM</h1>
      <img src={logo} alt="logo" width="300" height="300" />
    </div>
  )
}
