import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

// const PHASE_IN_MILLIS = 1000

let frameIndex = 1

interface Props {
  srcDoc: string
  // bufferSize: number
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  width: 100%;
`

interface FrameProps {
  zIndex: number
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Frame = styled.iframe<FrameProps>`
  position: relative;
  z-index: ${props => props.zIndex / 100000};
  opacity: 1;
  animation: ${fadeIn} 500ms linear 1;
  border: none;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 1;
`

interface Content {
  html: string
  index: number
  timestamp: number
}

export function BufferedFrame(props: Props) {
  const [contentStack, setContentStack] = useState<Content[]>([])

  useEffect(() => {
    // Add the current srcDoc to top of the stack if it isn't already on top
    const top = contentStack[contentStack.length - 1]
    const topHtml = top ? top.html : ''
    let newStack = contentStack
    if (topHtml !== props.srcDoc) {
      newStack = [
        ...contentStack,
        {
          html: props.srcDoc,
          index: frameIndex++,
          timestamp: new Date().getTime(),
        },
      ]
    }

    // // Every time something gets added need to make sure that content
    // // underneath eventually gets removed.
    // setTimeout(() => {
    //   // Never remove the last content
    //   if (newStack.length > 1) {
    //     const now = new Date().getTime()
    //     let cleanedStack = []

    //     // Loop through and remove any non-top content that has something on
    //     // top with a finished animation.
    //     for (let i = 0; i < newStack.length - 1; i++) {
    //       let newerComplete = false
    //       for (let j = i + 1; j < newStack.length; j++) {
    //         if (now - newStack[j].timestamp >= PHASE_IN_MILLIS) {
    //           newerComplete = true
    //           break
    //         }
    //       }
    //       if (!newerComplete) {
    //         cleanedStack.push(newStack[i])
    //       }
    //     }

    //     newStack = [...cleanedStack, newStack[newStack.length - 1]]
    //     setContentStack(newStack)
    //   }

    // }, PHASE_IN_MILLIS)

    setContentStack(newStack)
  }, [props.srcDoc, contentStack])

  return (
    <Wrapper>
      {contentStack.map(content => (
        <Frame
          zIndex={content.index}
          key={content.index}
          srcDoc={content.html}
        />
      ))}
    </Wrapper>
  )
}
