import { Repo, Block, Theme } from './types'
import pretty from 'pretty'

class RepoEvents extends EventTarget {
  changed() {
    this.dispatchEvent(new Event('changed'))
  }
}

export const events = new RepoEvents()

const headerBlock = {
  id: 'blk_1',
  name: 'Loan Offer',
  dml: `<dys-block>
  <dys-html>
    <style>
      .g1 {
        background: #EAFAF3 url(https://creditkarmacdn-a.akamaihd.net/res/content/mailings/ck/mortgage_efxinq_partner/triangle.png) no-repeat right;
        background-size: contain;
      }
    </style>
  </dys-html>
  
  <dys-row padding='0'>
    <dys-column width='45%' padding='0' css-class='g1'>
      <dys-text font-size='12px' font-family='OpenSans, Arial' font-weight='700' line-height='175%' color='#404345' padding='30px 55px 0 41px' editable='Offer'>
        Based on your profile, we've put together a few rates from our lender networks.
      </dys-text>
      <dys-table padding='20px 55px 20px 41px'>
        <tr>
          <th>
            <span class='current-heading' style='margin: 0; float: left; color: #939799; font-size: 10px;'>Credit score</span>
            <span class='current-value' style='margin: 0; font-weight: 800; font-size: 10px; color: #5A5C5E; float: right;'>821</span>
          </th>
        </tr>
        <tr>
          <td>
            <span class='current-heading' style='margin: 0; float: left; color: #939799; font-size: 10px;'>Monthly payment</span>
            <span class='current-value' style='margin: 0; font-weight: 800; font-size: 10px; color: #5A5C5E; float: right;'>$20 </span>
          </td>
        </tr>
        <tr>
          <td>
            <span class='current-heading' style='margin: 0; float: left; color: #939799; font-size: 10px;'>Current balance</span>
            <span class='current-value' style='margin: 0; font-weight: 800; font-size: 10px; color: #5A5C5E; float: right;'> $0 </span>
          </td>
        </tr>
        <tr>
          <td>
            <span class='current-heading' style='margin: 0; float: left; color: #939799; font-size: 10px;'>ZIP code</span>
            <span class='current-value' style='margin: 0; font-weight: 800; font-size: 10px; color: #5A5C5E; float: right;'>90210 </span>
          </td>
        </tr>
        <tr>
          <td>
            <span class='current-heading' style='margin: 0; float: left; color: #939799; font-size: 10px;'>Estimated Equity</span>
            <span class='current-value' style='margin: 0; font-weight: 800; font-size: 10px; color: #5A5C5E; float: right;'>$21,100</span>
          </td>
        </tr>
      </dys-table>
    </dys-column>
    
    <dys-column width='55%'>
      <dys-img src='https://29p3xo4710g84bygmn2df2g1-wpengine.netdna-ssl.com/wp-content/uploads/2016/11/cash-in-a-hand.jpg' width='64px' height='64px' editable='Check' />
      <dys-text color='#0091ff' font-size='18px' font-family='OpenSans, Arial' align='center' font-weight='700' padding-top='0px'> 12 months</dys-text>
      <dys-text color='#8c8c8c' font-size='10px' font-family='OpenSans, Arial' align='center' padding='0 0 0px 0'>NMLS ID#1234</dys-text>
      <dys-table>
        <tr>
          <th style='text-align:center;width:50%;font-size:10px;border-right:1px solid #eee; color: #8c8c8c;' editable='thing'>Interest rate</th>
          <td style='text-align:center;width:50%;font-size:10px; color: #8c8c8c;'>APR</td>
        </tr>
        <tr>
          <td style='color:#404345;text-align:center;font-size:24px;font-weight:600;border-right:1px solid #eee;'>5.02%</td>
          <td style='color:#404345;text-align:center;font-size:24px;font-weight:600;'>4.1%</td>
        </tr>
      </dys-table>
      <dys-button width='100%' font-size='13px' font-family='OpenSans, Arial' border-radius='2px' background-color='rgb(255, 134, 59)' padding='10px 30px 0 30px' editable='GetQuoteButton'>
        Get my quote
      </dys-button>
    </dys-column>
  </dys-row>
</dys-block>`,
}

const paragraphBlock = {
  id: 'blk_2',
  name: 'Greeting',
  dml: `<dys-block>
  <dys-html>
    <style>
      .gradient {
        background-image: linear-gradient(to left, #ffe8e8, #ffe8e8 50%, white 50%, white);
      }
      .height-130 {
        height: 130px;
      }
    </style>
  </dys-html>
  
  <dys-row><dys-column>Hello</dys-column></dys-row>
   
    <dys-row padding='0'>
      <dys-column width='58%' padding='0'>
        <dys-text font-family='OpenSans, Arial' font-size='14px' padding='0 0 0 25px' color='#404345' line-height='175%'>
        <p editable='edt_01d9dhbh4xz8f6de88adhkmevd'>
          @{text}
        </p>
        
        <p editable='edt_01d9dhbh4xc5jb2wsqa2m5ehsz'>
          <strong>it looks like a mortgage lender checked your credit on 11/12/2019.</strong> Your mortgage shopping window is closing soon! Make sure you take advantage of it while it's open. We searched out lender network and found rates for you.
        </p>
      </dys-text>
    </dys-column>
    
    <dys-column width='42%' padding='40px 0 0 0'>
      <dys-table>
        <tr>
          <td style='background-image: linear-gradient(to left, #ffe8e8, #ffe8e8 50%, white 50%, white);'>
            <img src='https://creditkarmacdn-a.akamaihd.net/res/content/mailings/ck/mortgage_refiinq_drip_03/calendar-icon.png' width='73px' height='73px' />
          </td>
          <td style='background-color: #ffe8e8;'>
            <p style='font-size: 12px; color: #812c2c; line-height: 1.5; margin: 18px 0px 1px;'>You have until</p>
            <p style='font-size: 18px; font-weight: 800; color: #812c2c; line-height: 1.5; margin: 0;'>1/2/3</p>
            <p style='font-size: 12px; color: #812c2c; line-height: 1.25; margin: 3px 0px 18px;'>to get more quotes without hurting your score.</p>
          </td>
        </tr>
      </dys-table>
    </dys-column>
  </dys-row>    
  
</dys-block>`,
}

const legalFooterBlock = {
  id: 'blk_3',
  name: 'Secondary Offer',
  dml: `<dys-block>
  <dys-row padding='0 40px'>
    
    <dys-column width='35%'>
      <dys-text color='#0091ff' font-family='Open Sans, Arial' font-size='14px' font-weight='700' line-height='175%' padding='0'>
        18 months
      </dys-text>
      <dys-text color='#0091ff' font-family='Open Sans, Arial' font-size='10px' font-weight='700' line-height='175%' padding='0'>
        from Some Bank
      </dys-text>
    </dys-column>
    
    <dys-column width='20%'>
      <dys-text color='#8c8c8c' font-family='Open Sans, Arial' font-size='10px' line-height='175%' padding='0'>
        Interest Rate
      </dys-text>
      <dys-text color='#404345' font-family='Open Sans, Arial' font-size='14px' font-weight='700' line-height='175%' padding='0'>
        3.1%
      </dys-text>
    </dys-column>
    
    <dys-column width='20%'>
      <dys-text color='#8c8c8c' font-family='Open Sans, Arial' font-size='10px' line-height='175%' padding='0'>
        APR
      </dys-text>
      <dys-text color='#404345' font-family='Open Sans, Arial' font-size='14px' font-weight='700' line-height='175%' padding='0'>
        4.8%
      </dys-text>
    </dys-column>
    
    <dys-column width='25%'>
      <dys-text color='#8c8c8c' font-family='Open Sans, Arial' font-size='10px' line-height='175%' padding='0' align='right'>
        NMLS #2468
      </dys-text>
      <dys-text color='#0091ff' font-family='Open Sans, Arial' font-size='14px' font-weight='700' line-height='175%' padding='0' align='right'>
        <a href='foo.com' style='color: #0091FF; text-decoration: none; font-size: 14px; font-weight: 700; line-height: 1.75;'>Get my quote &gt;</a>
      </dys-text>
    </dys-column>
    
  </dys-row>
</dys-block>`,
}

const theme1 = {
  id: 'thm_1',
  name: 'Base Theme',
  dml: `<dys-attributes><dys-body background-color="#fff" /></dys-attributes>`,
}

const template1 = {
  id: 'tmp_1',
  name: 'Test Template',
  blocks: [headerBlock, paragraphBlock, legalFooterBlock],
  theme: theme1,
}

const initialTestData: Repo = makePretty({
  blocks: [headerBlock, paragraphBlock, legalFooterBlock],
  themes: [theme1],
  templates: [template1],
})

function makePretty(repo: Repo): Repo {
  return {
    blocks: repo.blocks.map(b => ({ ...b, dml: pretty(b.dml) })),
    themes: repo.themes.map(t => ({ ...t, dml: pretty(t.dml) })),
    templates: repo.templates,
  }
}

let testData = { ...initialTestData }

export function getData() {
  return testData
}

export function addTheme(theme: Theme) {
  return setData({
    ...testData,
    themes: [...testData.themes, theme],
  })
}

export function addBlocks(...blocks: Block[]) {
  return setData({
    ...testData,
    blocks: [...testData.blocks, ...blocks],
  })
}

function upsertBlock(blocks: Block[], newBlock: Block): Block[] {
  const newBlocks = []
  let added = false
  for (const b of blocks) {
    if (b.id === newBlock.id) {
      newBlocks.push(newBlock)
      added = true
    } else {
      newBlocks.push(b)
    }
  }
  if (!added) {
    newBlocks.push(newBlock)
  }
  return newBlocks
}

function upsertTheme(themes: Theme[], newTheme: Theme): Theme[] {
  const newThemes = []
  let added = false
  for (const t of themes) {
    if (t.id === newTheme.id) {
      newThemes.push(newTheme)
      added = true
    } else {
      newThemes.push(t)
    }
  }
  if (!added) {
    newThemes.push(newTheme)
  }
  return newThemes
}

export function updateBlockDml(block: Block, newDml: string) {
  return setData({
    ...testData,
    blocks: upsertBlock(testData.blocks, { ...block, dml: newDml }),
  })
}

export function updateThemeDml(theme: Theme, newDml: string) {
  return setData({
    ...testData,
    themes: upsertTheme(testData.themes, { ...theme, dml: newDml }),
  })
}

function setData(repo: Repo) {
  testData = repo
  events.changed()
  return repo
}
