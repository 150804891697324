import React from 'react'
import styled from 'styled-components'

const TransparentBox = styled.div`
  background-color: #0005;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OverlayContent = styled.div`
  background-color: #222;
  border: 1px solid #bbb;
  border-radius: 10px;
  margin: 0;
  padding: 10px;
`

interface Props {
  children: JSX.Element[] | JSX.Element
  exit: () => void
}

export function Overlay(props: Props) {
  return (
    <TransparentBox onClick={props.exit}>
      <OverlayContent onClick={e => e.stopPropagation()}>
        {props.children}
      </OverlayContent>
    </TransparentBox>
  )
}
