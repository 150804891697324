import React, { useRef, useEffect, useState } from 'react'
import * as monaco from 'monaco-editor'
import styled from 'styled-components'

interface EditorContainerProps {
  width: string
  height: string
}

const EditorContainer = styled.div<EditorContainerProps>`
  width: ${props => props.width};
  height: ${props => props.height};
`

interface Props {
  value: string
  language: string
  width: string
  height: string
  onChange: (content: string) => void
}

export function Editor(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [
    editor,
    setEditor,
  ] = useState<monaco.editor.IStandaloneCodeEditor | null>(null)

  const { language, value, onChange } = props

  useEffect(() => {
    if (containerRef.current) {
      console.log('create editor')
      const monacoEditor = monaco.editor.create(containerRef.current, {
        // value: props.value,
        // language: 'html',
        minimap: { enabled: false },
        theme: 'vs-dark',
      })
      setEditor(monacoEditor)
    }
  }, [])

  useEffect(() => {
    if (editor && editor.getValue() !== value) {
      console.log('update value')
      editor.setValue(value)
    }
  }, [value, editor])

  useEffect(() => {
    if (editor) {
      console.log('update onChange')
      editor.onDidChangeModelContent(() => {
        onChange(editor.getValue())
      })
    }
  }, [onChange, editor])

  useEffect(() => {
    if (editor && editor.getModel()!.getModeId() !== language) {
      console.log('update language')
      monaco.editor.setModelLanguage(editor.getModel()!, language)
    }
  }, [language, editor])

  return (
    <EditorContainer
      ref={containerRef}
      width={props.width}
      height={props.height}
    />
  )
}
