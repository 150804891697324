import React from 'react'
import styled from 'styled-components'
import { HashRouter as Router, Route, Redirect } from 'react-router-dom'
import { DevEnv } from './DevEnv'
import { testSplit } from './ChoppingBoard'

testSplit()

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100wh;
  align-items: center;
  justify-content: center;
`

const App: React.FC = () => {
  return (
    <Container>
      <Router>
        <Route path="/devenv" component={DevEnv} />
        <Redirect to="/devenv" />
      </Router>
    </Container>
  )
}

export default App
