import React, { useState, useEffect } from 'react'
import { Block, Template } from '../model/types'
import * as store from '../model/test-data'
import { render, hasLoaded } from '../util/dml'
import styled from 'styled-components'
import Editor from 'react-monaco-editor'
import { useInterval, useRepo } from '../util/hooks'
import { Overlay } from './Overlay'
import { ChopWizard } from './ChopWizard'
import { useLocalState } from '../util/hooks'
import { BufferedFrame } from './BufferedFrame'
import { Loading } from './Loading'
import { newId, newName } from '../util/igden'

const MONACO_THEME = 'vs-dark'
const MONACO_OPTIONS = {
  minimap: {
    enabled: false,
  },
  automaticLayout: true,
}

const Stack = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
`

const StackItem = styled.div<{ zIndex: number }>`
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 1;
  width: 100%;
  height: 100%;
  z-index: ${props => props.zIndex};
`

export function DevEnv() {
  const { block, theme, template } = useLocalState()
  const [previewHtml, setPreviewHtml] = useState('')
  const [isChopping, setIsChopping] = useState(false)

  if (window.location.hash.split('/').length < 4) {
    window.location.hash = `#/devenv/${theme.id}/${block.id}/${template.id}`
  }

  useEffect(() => {
    const rendered = render(block.dml, theme.dml)
    if (rendered !== null) {
      setPreviewHtml(rendered)
    }
  }, [theme, block, template])

  function addBlocks(blocks: Block[], themeDml: string) {
    const newTheme = {
      dml: themeDml,
      id: newId('thm'),
      name: newName(),
    }
    store.addBlocks(...blocks)
    store.addTheme(newTheme)

    // Change the URL so that we are viewing new stuff
    window.location.hash = `#/devenv/${newTheme.id}/${blocks[0].id}/${
      template.id
    }`

    setIsChopping(false)
  }

  function updatePreview() {
    const rendered = render(block.dml, theme.dml)
    if (rendered !== null) {
      setPreviewHtml(rendered)
    }
  }

  return (
    <Stack>
      <StackItem zIndex={1}>
        <Wrapper>
          <MainToolbar>
            <button onClick={() => setIsChopping(true)}>
              Foreign Email Chop
            </button>
          </MainToolbar>
          <SideBar />
          <ContentEditor />
          <ContentPreview html={previewHtml} ready={updatePreview} />
        </Wrapper>
      </StackItem>
      {isChopping && (
        <StackItem zIndex={2}>
          <Overlay exit={() => setIsChopping(false)}>
            <ChopWizard chopped={addBlocks} />
          </Overlay>
        </StackItem>
      )}
    </Stack>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-columns: 200px 1fr 850px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`

const MainToolbar = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: #000;
  padding: 4px;
`

const SideBarContent = styled.div`
  display: grid;
  grid-template-rows: auto 3fr auto 1fr auto 1fr;
  flex-direction: column;
  border-right: 1px solid #555;
  grid-row-start: 2;
  grid-column-start: 1;
`

const SideBarSubtitle = styled.div`
  width: 100%;
  background-color: #555;
  color: #ccc;
  font-weight: bold;
  font-size: 14px;
  padding: 8px;
  border-right: 1px solid #555;
`

const SideBarList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
`

const SideBarLink = styled.a<{ selected: boolean }>`
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #ccc;
  text-decoration: none;
  line-height: 20px;
  background-color: ${props => (props.selected ? '#444' : 'transparent')};
  &:hover {
    background-color: #444;
  }
`

function SideBar() {
  const opts = useLocalState()
  const repo = useRepo()
  return (
    <SideBarContent>
      <SideBarSubtitle>Blocks</SideBarSubtitle>
      <SideBarList>
        {repo.blocks.map(b => (
          <SideBarLink
            key={b.id}
            href={`#/devenv/${opts.theme.id}/${b.id}/${opts.template.id}`}
            selected={b.id === opts.block.id}
          >
            &nbsp;
            <span role="img" aria-label="block">
              🀰
            </span>
            &nbsp;{b.name}
          </SideBarLink>
        ))}
      </SideBarList>
      <SideBarSubtitle>Themes</SideBarSubtitle>
      <SideBarList>
        {repo.themes.map(t => (
          <SideBarLink
            key={t.id}
            href={`#/devenv/${t.id}/${opts.block.id}/${opts.template.id}`}
            selected={t.id === opts.theme.id}
          >
            &nbsp;
            <span role="img" aria-label="theme">
              🌈
            </span>
            &nbsp;{t.name}
          </SideBarLink>
        ))}
      </SideBarList>
      <SideBarSubtitle>Templates</SideBarSubtitle>
      <SideBarList>
        {repo.templates.map(t => (
          <SideBarLink
            key={t.id}
            href={`#/devenv/${opts.theme.id}/${opts.block.id}/${t.id}`}
            selected={t.id === opts.template.id}
          >
            &nbsp;
            <span role="img" aria-label="template">
              🧾
            </span>
            &nbsp;{t.name}
          </SideBarLink>
        ))}
      </SideBarList>
    </SideBarContent>
  )
}

const ContentEditorContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;
  border-right: 1px solid #999;
  box-shadow: rgba(18, 18, 18, 0.3) 6px 0px 4px -2px;
  background-color: #222;
  z-index: 2;
  grid-column-start: 2;
  grid-row-start: 2;
`

const TemplateEditorContainer = styled.div`
  grid-column-start: 3;
  grid-row-start: 2;
`

function ContentEditor() {
  const opts = useLocalState()
  return (
    <ContentEditorContainer>
      <>
        <ThemeEditorWrapper>
          <ThemeEditor
            onChange={dml => store.updateThemeDml(opts.theme, dml)}
          />
        </ThemeEditorWrapper>
        <BlockEditor onChange={dml => store.updateBlockDml(opts.block, dml)} />
      </>
    </ContentEditorContainer>
  )
}

interface TemplateEditorProps {
  template: Template
  html: string
}

function TemplateEditor(props: TemplateEditorProps) {
  return <BufferedFrame srcDoc={props.html} />
}

interface ContentPreviewProps {
  html: string
  ready: () => void
}

function ContentPreview(props: ContentPreviewProps) {
  const opts = useLocalState()
  const [isReady, setIsReady] = useState(false)

  useInterval(() => {
    if (isReady) {
      return
    }
    if (hasLoaded()) {
      setIsReady(true)
      props.ready()
    }
  }, 4500)

  return (
    <TemplateEditorContainer>
      {isReady && <TemplateEditor template={opts.template} html={props.html} />}
      {!isReady && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
    </TemplateEditorContainer>
  )
}

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
`

const BlockEditorContainer = styled.div`
  grid-row-start: 2;
  width: 100%;
  height: 100%;
`

interface BlockEditorProps {
  onChange: (dml: string) => void
}

function BlockEditor(props: BlockEditorProps) {
  const opts = useLocalState()
  return (
    <BlockEditorContainer>
      <Editor
        value={opts.block.dml}
        language="html"
        onChange={props.onChange}
        theme={MONACO_THEME}
        options={MONACO_OPTIONS}
      />
    </BlockEditorContainer>
  )
}

const ThemeEditorWrapper = styled.div`
  grid-row-start: 1;
  border-bottom: 1px solid #555;
`

interface ThemeEditorProps {
  onChange: (dml: string) => void
}

function ThemeEditor(props: ThemeEditorProps) {
  const opts = useLocalState()
  return (
    <Editor
      value={opts.theme.dml}
      language="html"
      onChange={props.onChange}
      theme={MONACO_THEME}
      options={MONACO_OPTIONS}
    />
  )
}
