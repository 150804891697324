import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface WindowProps {
  children: ReactNode
}

const WindowWrapper = styled.div`
  display: grid;
  grid-template-rows: 30px 1fr;
  width: 100%;
  height: 100%;
`

export function Window(props: WindowProps) {
  return <WindowWrapper>{props.children}</WindowWrapper>
}
