import { useEffect, useRef, useState } from 'react'
import * as store from '../model/test-data'

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function useLocalState() {
  function getUrlOptions() {
    const data = store.getData()
    const parts = window.location.hash.split('/')
    const theme = data.themes.find(t => t.id === parts[2]) || data.themes[0]
    const block = data.blocks.find(b => b.id === parts[3]) || data.blocks[0]
    const template =
      data.templates.find(t => t.id === parts[4]) || data.templates[0]
    return {
      theme,
      block,
      template,
    }
  }

  const [state, setState] = useState(getUrlOptions())

  useEffect(() => {
    const handler = () => {
      setState(getUrlOptions())
    }
    window.addEventListener('hashchange', handler, false)
    store.events.addEventListener('changed', handler, false)

    return () => {
      window.removeEventListener('hashchange', handler)
      store.events.removeEventListener('changed', handler)
    }
  }, [])

  return state
}

export function useRepo() {
  const [repo, setRepo] = useState(store.getData())
  useEffect(() => {
    function onChange() {
      setRepo(store.getData())
    }
    store.events.addEventListener('changed', onChange, false)
    return () => store.events.removeEventListener('changed', onChange)
  }, [])
  return repo
}